import classNames from "classnames";
import { graphql } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import React from "react";
import { useGlobalState } from "../state/globalState";
const { parse } = require("rss-to-json");

export type TinkRSS = {
  title: string;
  description: string;
  link: string;
  category: string[];
  items: {
    title: string;
    description: string;
    link: string;
    published: number;
    created: number;
  }[];
};

const Status: React.FC = () => {
  const { t } = useTranslation(["common"]);
  const [statusData, setStatusData] = React.useState<TinkRSS>();

  const [headerOptions, setHeaderOptions] = useGlobalState("headerOptions");

  React.useEffect(() => {
    setHeaderOptions({
      ...headerOptions,
      colorScheme: "light",
      hidden: false,
    });

    parse(`https://tinkgermany.statuspage.io/history.rss`).then(
      (rss: TinkRSS) => {
        setStatusData(rss);
      }
    );
  }, []);

  return (
    <div className="py-24 container-3xl">
      <div className="flex flex-col gap-4">
        {statusData &&
          statusData.items.map((item) => (
            <div
              className={classNames("rounded-xl", {
                "bg-gray-100":
                  item.description.includes("Resolved") ||
                  item.description.includes("Completed"),
                "bg-red-100":
                  !item.description.includes("Resolved") &&
                  !item.description.includes("Completed"),
              })}
              key={item.link}
            >
              <div className="px-6 pt-6 pb-6 text-lg font-bold">
                {item.title}
              </div>
              <hr
                className={classNames({
                  "border-t-gray-200":
                    item.description.includes("Resolved") ||
                    item.description.includes("Completed"),
                  "border-t-red-200":
                    !item.description.includes("Resolved") &&
                    !item.description.includes("Completed"),
                })}
              />
              <div
                className="px-6 pt-2 pb-6 prose"
                dangerouslySetInnerHTML={{ __html: item.description }}
              />
            </div>
          ))}
      </div>
    </div>
  );
};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default Status;
